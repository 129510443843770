import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import LogoComponent from '../subComponents/LogoComponent'
import SocialIcons from '../subComponents/SocialIcons'
import BigTitle from '../subComponents/BigTitlte'
import Intro from '../components/Intro'
import { motion } from 'framer-motion'
import Dev from '../subComponents/Dev'

// import { Canvas, useFrame } from "@react-three/fiber"
// import { OrbitControls, MeshWobbleMaterial } from '@react-three/drei'
// import { softShadows, MeshWobbleMaterial, OrbitControls } from '@react-three/drei'

const MainContainer = styled(motion.div)`
    background: ${props => props.theme.body};
    width: 100vw;
    height: 100vh;
    overflow:hidden;
    position: relative;
    h2,h3,h4,h5,h6{
        font-family:'Karla', sans-serif ;
        font-weight:500;
    }
`
const Container = styled.div`
    padding: 2rem;
`

const Values = styled.div`
    position: absolute;
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
    bottom: 0;
    right: 0;
    left: 0
`
const Value = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem

`
// Framer-motion config
const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,
  
      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }
  
  }

// softShadows()

// const SpinningMesh = ({ position, color, speed, args }) => {
//     //ref to target the mesh
//     const mesh = useRef();
  
//     //useFrame allows us to re-render/update rotation on each frame
//     useFrame(() => (mesh.current.rotation.x = mesh.current.rotation.y += 0.01));
  
//     //Basic expand state
//     const [expand, setExpand] = useState(false);
//     // React spring expand animation
//     // const props = useSpring({
//     //   scale: expand ? [1.4, 1.4, 1.4] : [1, 1, 1],
//     // });
//     return (
//       <mesh
//         position={position}
//         ref={mesh}
//         onClick={() => setExpand(!expand)}
//         scale={[1, 1, 1]}
//         castShadow>
//         <boxBufferGeometry attach='geometry' args={args} />
//         <MeshWobbleMaterial
//           color={color}
//           speed={speed}
//           attach='material'
//           factor={0.6}
//         />
//       </mesh>
  
//       //Using Drei box if you want
//       // <Box {...props} ref={mesh} castShadow>
//       //   <MeshWobbleMaterial
//       //     {...props}
//       //     attach='material'
//       //     factor={0.6}
//       //     Speed={1}
//       //   />
//       // </Box>
//     );
//   };

const Main = () => {
    return (
        <MainContainer
            variants={container}
            initial='hidden'
            animate='show'
            exit={{
                opacity:0, transition:{duration: 0.5}
            }}
        >
            <Container>
                <LogoComponent/>
                <SocialIcons />
                <Dev />
            </Container>
            {/* <Canvas
                style={{
                    position: "absolute",
                    width: "50vw",
                    right: 0
                }}
                colorManagement
                shadowMap
                camera={{ position: [-5, 2, 10], fov: 60 }}>
                <ambientLight intensity={0.3} />
                <directionalLight
                castShadow
                position={[0, 10, 0]}
                intensity={1.5}
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                shadow-camera-far={50}
                shadow-camera-left={-10}
                shadow-camera-right={10}
                shadow-camera-top={10}
                shadow-camera-bottom={-10}
                />
                <pointLight position={[-10, 0, -20]} intensity={0.5} />
                <pointLight position={[0, -10, 0]} intensity={1.5} />
                <group>
                <mesh
                    rotation={[-Math.PI / 2, 0, 0]}
                    position={[0, -3, 0]}
                    receiveShadow>
                    <planeBufferGeometry attach='geometry' args={[100, 100]} />
                    <shadowMaterial attach='material' opacity={0.3} />
                </mesh>
                <SpinningMesh
                    position={[0, 1, 0]}
                    color='lightblue'
                    args={[3, 2, 1]}
                    speed={2}
                />
                <SpinningMesh position={[-2, 1, -5]} color='pink' speed={6} />
                <SpinningMesh position={[5, 1, -2]} color='pink' speed={6} />
                </group>
                <OrbitControls />
            </Canvas> */}
            <Intro click={true} />
            <Values>
                <Value>
                    <h2>
                        100%
                    </h2>
                    <span>Green Energy</span>
                </Value>
                <Value>
                    <h2>
                        24/7
                    </h2>
                    <span>Customer Service</span>
                </Value>
                <Value>
                    <h2>
                        1 Year
                    </h2>
                    <span>Warranty</span>
                </Value>
                <Value>
                    <h2>
                        Free
                    </h2>
                    <span>Delivery</span>
                </Value>
            </Values>
            <BigTitle text="Home" top="5rem" right="6rem" color="#0002"/>
        </MainContainer>
    )
}

export default Main
