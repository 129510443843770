// blog data

export const Courses = [

    {
        id: 0,
        thumbnail: "",
        price: 30000,
        image: "https://bigthink.com/wp-content/uploads/2021/04/origin-96.jpg?resize=320,180",
        title: "Computer Vision",
        link: "https://aizakoa.test/",
        author: "Abioud RABEZANAHARY",
        voice: "Abioud RABEZANAHARY",
        tags:["OpenCV", "Python"],
        cert: 1,
        summary: "La puissance de la chimie et de l'informatique combinées.",
        created_at: "2022-05-05",
        start: 16,
        end: 18,
        mounth: "Juin",
        year: 2022,
    },
    {
        id: 1,
        thumbnail: "",
        price: 0,
        image: "https://bigthink.com/wp-content/uploads/2021/05/origin-56.jpg?resize=320,180",
        title: "Introduction à l'électronique",
        link: "https://aizakoa.test/",
        author: "Paul Ratner",
        tags:["Arduino",],
        cert: 1,
        summary: "New studies stretch the boundaries of physics, achieving quantum entaglement in larger systems.",
        created_at: "2021-05-16",
        start: 20,
        end: 20,
        mounth: "Juin",
        year: 2022,
    },
    {
        id: 2,
        thumbnail: "",
        price: 0,
        image: "https://bigthink.com/wp-content/uploads/2021/05/origin-4.jpg?resize=320,180",
        title: "Modélisation 3D",
        link: "https://aizakoa.test/",
        author: "Robby Berman",
        tags:["blender","tablet_graphique"],
        cert: 0,
        summary: "Want to live 100+ years? You may need unusually good DNA repair.",
        created_at: "2021-05-11",
        start: 11,
        end: 13,
        mounth: "Juillet",
        year: 2022,
    },
    {
        id: 3,
        thumbnail: "",
        price: 20000,
        image: "https://bigthink.com/wp-content/uploads/2020/07/origin-27.jpg?resize=320,180",
        title: "IoT avec les microcontrolleurs ESP",
        link: "https://aizakoa.test/",
        author: "Paul Ratner",
        tags:["ESP32","ESP8266"],
        cert: 1,
        summary: "Researchers discover how to use light instead of electricity to advance artificial intelligence.",
        created_at: "2020-07-26",
        start: 14,
        end: 16,
        mounth: 'Juillet',
        year: 2022,
    },
] 