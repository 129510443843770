// import {createProxyMiddleware} from 'http-proxy-middleware'
const axios = require('axios').default;
const qs = require('qs');
const cors = require("cors");

const mainUrl = "https://api.iopi.mg";

export function postOrderToApi(request) {
    const url = mainUrl + "/api/order"

    var data = new FormData();
    data.append("firstname", request.firstname);
    data.append("lastname", request.lastname);
    data.append("email", request.email);
    data.append("phonenumber", request.phonenumber);
    data.append("qty", request.qty);
    data.append("message", request.message);
    data.append("product", request.product);

    var init = {
        method: 'POST',
        url: url,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        mode: 'no-cors',
        data: data,
        redirect: 'follow'
    }
    return axios(init)
        .then((response) => response.data)
        .catch((error) => console.error(error))
}

export function getApiHome (){
    const url = mainUrl + "/api/home";
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
        })
        .then((response) => response.data)
        .catch((error) => console.error(error))
}

export function getApiProjects (){
    const url = mainUrl + "/api/projects";
    return axios({
          method: 'get',
          url: url,
          mode: 'no-cors',
        })
          .then((response) => response.data)
          .catch((error) => console.error(error))
}

export function getApiSingleProject (id){
    const url = mainUrl + "/api/projects/" + id;
    return axios({
          method: 'get',
          url: url,
          mode: 'no-cors',
        })
          .then((response) => response.data)
          .catch((error) => console.error(error))
}

export function getApiBlogs (){
    const url = mainUrl + "/api/blogs";
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
      })
        .then((response) => response.data)
        .catch((error) => console.error(error))
}

export function getApiAbout(){
    const url = mainUrl + "/api/about";
    return axios({
        method: 'get',
        url: url,
        mode: 'no-cors',
    })
        .then((response) => response.data)
        .catch((error) => console.error(error))
}
