import styled, { ThemeProvider } from 'styled-components'
import {lightTheme} from '../components/Themes';
import {motion} from 'framer-motion';

import LogoComponent from '../subComponents/LogoComponent';
import SocialIcons from '../subComponents/SocialIcons';

import Dev from '../subComponents/Dev';
import VideoPlayer from '../subComponents/VideoPlayer';

const Box = styled(motion.div)`
	background-color: ${props => props.theme.body};
	height: 100vh;
    width: 100vw;
    overflow-y: scroll
`

const Container = styled.div`
    background-color: ${props => `rgba(${props.theme.bodyRgba},0.8)`};
    width: 100%;
    height:auto;

    position: relative;
    // padding-bottom: 5rem;
`

// Framer-motion Configuration
const container = {

  hidden: {opacity:0},
  show: {
    opacity:1,

    transition:{
      staggerChildren: 0.5,
      duration: 0.5,
    }
  }

}

const Iopi = () => {

    return (
      <ThemeProvider theme={lightTheme}>
			<Box
				variants={container}
				initial='hidden'
				animate='show'
				exit={{
					opacity:0, transition:{duration: 0.5}
				}}
			>
				<Container>
          <LogoComponent/>
            <SocialIcons/>
            <Dev />
            <div
              style={{
                display: "flex",
                padding: "3rem",
                paddingTop: "6rem",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
                <div
                  style={{
                      width: "100%",
                      maxWidth: 900,
                      paddingRight: "3rem",
                  }}
                >
                    <h1
                      style={{
                        paddingLeft: "1rem",
                      }}
                    >
                        Who we are?
                    </h1>
                    <hr />
                    <p
                      style={{
                        paddingLeft: "3rem",
                      }}
                    >
                        We do not inherit the land from our ancestors, we borrow it from our children. So, when we are no more, where will the world be? And what will our children inherit? The question is all the more important as the impact of waste on our world is increasingly felt.
                        <br />
                        <br />
                        IOPI or Input Output for a Proper Impact Is a startup that was created during Orange Summer Challenge 2022, it aims to reduce the negative impacts of waste by recovering it.
                    </p>
                </div>
            </div>
            <div
              style={{
                display: "flex",
                padding: "3rem",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  maxWidth: 800,
                }}
              >
                <VideoPlayer src="https://youtu.be/F1mG6aNHpfs"/>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                padding: "3rem",
                paddingTop: "6rem",
                paddingLeft: "4rem",
                width: "100%",
                justifyContent: "center",
                flexWrap: "wrap"
              }}
            >
                <div
                  style={{
                      width: "100%",
                      maxWidth: 900,
                      paddingRight: "3rem",
                  }}
                >
                    <h1
                      style={{
                        paddingLeft: "1rem",
                      }}
                    >
                        Our Mission.
                    </h1>
                    <hr />
                    <p
                      style={{
                        paddingLeft: "3rem",
                      }}
                    >
                        IOPI, or Input/Output for a Proper Impact, is on a mission to raise awareness by initiating the "Clean Tech" movement. IOPI is committed to developing appropriate technological solutions to achieve a more appropriate impact on the environment and society.
                        <br />
                        <br />
                        Within this framework, IOPI's first project concerns the valorization of urine. Two major problems have been raised: on the one hand, the frequent electricity cuts and the increase in fuel prices, and on the other hand, the corridors of the cities which are covered by urine creating nauseating odors. To solve these problems, IOPI has developed a generator that produces electricity from urine, a resource that is available in abundance but not yet exploited to its full potential.
                    </p>
                </div>
            </div>
            <div
              style={{
                display: "flex",
                padding: "3rem",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  maxWidth: 800,
                }}
              >
                <VideoPlayer src="https://youtu.be/US8OGHvV7M0"/>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                padding: "3rem",
                paddingTop: "6rem",
                paddingLeft: "4rem",
                width: "100%",
                justifyContent: "center",
                flexWrap: "wrap"
              }}
            >
                <div
                  style={{
                      width: "100%",
                      maxWidth: 900,
                      paddingRight: "3rem",
                  }}
                >
                    <h1
                      style={{
                        paddingLeft: "1rem",
                      }}
                    >
                        Our Vision.
                    </h1>
                    <hr />
                    <p
                      style={{
                        paddingLeft: "3rem",
                      }}
                    >
                        In order to create a bright future for future generations, we must focus our efforts on the present. IOPI, through the actions it is currently undertaking, is helping to build a future where waste will be recovered and have much less negative impact on the environment. Hence the slogan \"IOPI towards a clean and sustainable future.
                    </p>
                </div>
            </div>
          </Container>
        	</Box>
        </ThemeProvider>
    )
}

export default Iopi
