import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {motion} from 'framer-motion'
import '../assets/customCss/extra.css'

const Box = styled(motion.div)`
    position: absolute;
    left: 47%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 65vw;
    height: 350px;
    display: flex;

    background-repeat: no-repeat;
    background-size: 100% 2px;
    z-index:1;
`

const SubBox = styled.div`
    width: 100%;
    max-width: 550px;
    position: relative;
    display: flex;
    // background-color: #FFFFFF66;
    // backdrop-filter: blur(15px);
    // border-radius: 0 30px 30px 0;
    // box-shadow: #00000033 0 10px 20px
`

const Text = styled.div`
    font-size: calc(1em + 1.5vw);
    // color: ${props => props.theme.body};
    color: black;
    padding: 2rem;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: start;

    &>*:last-child{
        // color: ${props => `rgba(${props.theme.bodyRgba},0.6)` };
        color: ${props => `rgba(0, 0, 0, 0.6)` };
        font-weight:300;
        
        font-size: calc(0.9rem + 1.5vw);

        @media (min-width: 600px){
            font-size: calc(1rem + 1.5vw);
        }
    
        @media (min-width: 835px){
            font-size: calc(0.5rem + 1.5vw);
        }
    
        @media (min-width: 1080px){
            font-size: 1.7rem;
        }

    }
`

const Intro = () => {

    useEffect(() => {

        var hint = document.getElementsByClassName("typingH")
        var title = document.getElementsByClassName("typingT")
        var element = document.getElementsByClassName("typing_t")
        // var tdiv = document.getElementsByClassName("typing_div")

        console.log({title: title[0].innerHTML, element: element[0].innerHTML})

        const typeWriter = () => {
            try{
                if (i < txt.length){
                    hint[0].innerHTML = txt[i].hint
                    title[0].innerHTML = "<span class='fade-in' id='first'>"+txt[i].title+"</span>"
                    element[0].innerHTML = "<span class='fade-in2' id='second'>"+txt[i].text+"</span>"
                    if (i === (txt.length - 1)){
                        i = 0
                        speed = 9000
                    }else{
                        speed = 9000
                        i++
                    }
                }
                setTimeout(typeWriter, speed)
            } catch {
                return null
            }
            // try{
            //     var txtarr = (txt[j].text).split(" ")
            //     if(i === 0){
            //         element[0].innerHTML = ""
            //         title[0].innerHTML = txt[j].title
            //         title[0].setAttribute("style", "transition: all 1s ease; filter: opacity(1); color: #555")
            //         tdiv[0].setAttribute("style", "transition: all 1.5s ease; filter: opacity(1)")
            //     }
            //     if (i < txtarr.length){
            //         element[0].innerHTML += " "+"<span class='fade-in'>"+txtarr[i]+"</span>"
            //         if (i === (txtarr.length - 1)){
            //             if (j === (txt.length - 1)){
            //                 i = 0
            //                 j = 0
            //                 tdiv[0].setAttribute("style", "transition: all 1s ease; filter: opacity(0)")
            //                 title[0].setAttribute("style", "transition: all 0.5s ease; filter: opacity(0)")
            //             }else{
            //                 i = 0
            //                 j++
            //                 tdiv[0].setAttribute("style", "transition: all 1s ease; filter: opacity(0)")
            //                 title[0].setAttribute("style", "transition: all 0.5s ease; filter: opacity(0)")
            //             }
            //             speed = 1000
            //         }else{
            //             speed = 300
            //             i++
            //         }
            //     }
            //     setTimeout(typeWriter, speed)
            // } catch {
            //     return null
            // }
            // try{
            //     if(i === 0){
            //         element[0].innerHTML = ""
            //         title[0].innerHTML = txt[j].title
            //         title[0].setAttribute("style", "transition: all 1s ease; filter: opacity(1); color: #555")
            //         tdiv[0].setAttribute("style", "transition: all 1.5s ease; filter: opacity(1)")
            //     }
            //     if (i < txt[j].text.length){
            //         element[0].innerHTML += txt[j].text.charAt(i)
            //         if (txt[j].text.charAt(i) === " "){
            //             speed = 100
            //             i++
            //         }else if (txt[j].text.charAt(i) === "," || txt[j].text.charAt(i) === ";"){
            //             speed = 125
            //             i++
            //         }else if (txt[j].text.charAt(i) === "."){
            //             if (j === (txt.length - 1)){
            //                 i = 0
            //                 j = 0
            //                 tdiv[0].setAttribute("style", "transition: all 1s ease; filter: opacity(0)")
            //                 title[0].setAttribute("style", "transition: all 0.5s ease; filter: opacity(0)")
            //             }else{
            //                 i = 0
            //                 j++
            //                 tdiv[0].setAttribute("style", "transition: all 1s ease; filter: opacity(0)")
            //                 title[0].setAttribute("style", "transition: all 0.5s ease; filter: opacity(0)")
            //             }
            //             speed = 1000
            //         }else{
            //             speed = 50
            //             i++
            //         }
            //     }
            //     setTimeout(typeWriter, speed)
            // } catch {
            //     return null
            // }
        }

        typeWriter()
        return()=>{
            console.log('OUT')
        }
    }, []);

    var i = 0
    var j = 0
    const txt = 
            [
                {
                    hint: "IOPI,",
                    title: "Welcome",
                    text: "<b>Imagine a means within the reach and use of all; a set of innovative technologies that transform urine into a sustainable resource.</b>"
                },
                {
                    hint: "IOPI,",
                    title: "Clean",
                    text: "<b>We want to offer clean, ecological, economical and accessible electricity to all.</b>"
                },
                {
                    hint: "IOPI,",
                    title: "Free",
                    text: "<b>A solution that both makes it possible to satisfy access to basic services such as access to sanitary and hygiene facilities.</b>"
                },
                {
                    hint: "IOPI,",
                    title: "Sustainable",
                    text: "<b>Technologies for a promise of a sustainable future.</b>"
                },
            ]

    var speed = 50;

    return (
        <Box
            initial={{
                top:"60%",
            }}
            animate={{
                top: '50%',
            }}
            transition={{ type: 'spring', duration:2, delay:1 }}
        >
            <SubBox>
                <Text>
                    <h1 className='typingH' style={{filter: "opacity(1)", color:" #9251ac", fontWeight: "bolder", fontSize: 70}}>

                    </h1>
                    <h3 className='typingT' style={{color: "#555", fontWeight: "bold", fontSize: 35, paddingBottom: 15}}>
                    </h3>
                    <h6 className='typing_div' style={{fontSize: 22}}>
                        {/* &gt;&nbsp; */}
                        <span className='typing_t'>

                        </span>
                    </h6>
                </Text>
            </SubBox>
        </Box>
    )
}

export default Intro
