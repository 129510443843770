import React from 'react'
import styled from 'styled-components'
import { GoogleLogout } from 'react-google-login';


const Container = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
	z-index:3;
    right: 1rem;
	top: 1.2rem;
`

const responseGoogle = (response) => {
    console.log("Log out successfully");
  }

const GoogleLogOut = (props) => {
    return (
        <Container>
            <GoogleLogout
                clientId="963666513862-1mqp5pi1cimtr82qc9nob1eh4o3llnko.apps.googleusercontent.com"
                render={renderProps => (
                    <button
                        style={{
                            borderWidth: 1,
                            borderColor: "black",
                            borderRadius: 5,
                            backgroundColor: "white",
                            padding: 5,
                            paddingLeft: 10,
                            paddingRight: 10,
                        }}
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                    >
                        Se déconnecter
                    </button>
                  )}
                buttonText="Se déconnecter"
                onLogoutSuccess={responseGoogle}
            />
        </Container>
    )
}

export default GoogleLogOut
