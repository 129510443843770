import React, { Suspense, useRef } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {lightTheme} from '../components/Themes';
import {motion} from 'framer-motion';

import LogoComponent from '../subComponents/LogoComponent';
import SocialIcons from '../subComponents/SocialIcons';

import Dev from '../subComponents/Dev';

import * as THREE from 'three'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Preload, Image as ImageImpl } from '@react-three/drei'
import { ScrollControls, Scroll, useScroll } from '@react-three/drei'

import image1 from '../assets/Images/Team/Mission.JPG'
import image2 from '../assets/Images/Team/Vision.JPG'
import image3 from '../assets/Images/Team/Equipe.JPG'

const Box = styled(motion.div)`
	background-color: ${props => props.theme.body};
	height: 100vh;
    width: 100vw;
    overflow-y: scroll
`

const Container = styled.div`
    background-color: ${props => `rgba(${props.theme.bodyRgba},0.8)`};
    width: 100%;
    height:auto;

    position: relative;
    // padding-bottom: 5rem;
`

// Framer-motion Configuration
const container = {

  hidden: {opacity:0},
  show: {
    opacity:1,

    transition:{
      staggerChildren: 0.5,
      duration: 0.5,
    }
  }

}

function Image(props) {
    const ref = useRef()
    const group = useRef()
    const data = useScroll()
    useFrame((state, delta) => {
      group.current.position.z = THREE.MathUtils.damp(group.current.position.z, Math.max(0, data.delta * 50), 4, delta)
      ref.current.material.grayscale = THREE.MathUtils.damp(ref.current.material.grayscale, Math.max(0, 1 - data.delta * 1000), 4, delta)
    })
    return (
      <group ref={group}>
        <ImageImpl ref={ref} {...props} />
      </group>
    )
  }
  
  function Page({ m = 0.4, urls, ...props }) {
    const { width } = useThree((state) => state.viewport)
    const w = width < 10 ? 1.5 / 3 : 1 / 3
    return (
      <group {...props}>
        {/* <Image position={[0.1*width, 0, 0]} scale={[width * w - m * 2, 5, 1]} url={urls[0]} /> */}
        <Image position={[width * w, 0, 1]} scale={[width * w - m * 2, 4, 1]} url={urls[1]} />
      </group>
    )
  }
  
  function Pages() {
    const { width } = useThree((state) => state.viewport)
    return (
      <>
        <Page position={[-width * 1, 0, 0]} urls={[image1, image1]} />
        <Page position={[width * 0, 0, 0]} urls={[image2, image2]} />
        <Page position={[width * 1, 0, 0]} urls={[image3, image3]} />
        <Page position={[width * 2, 0, 0]} urls={[image1, image1]} />
        <Page position={[width * 3, 0, 0]} urls={[image2, image2]} />
        <Page position={[width * 4, 0, 0]} urls={[image3, image3]} />
      </>
    )
  }

const EquipePage = () => {

    return (
      <ThemeProvider theme={lightTheme}>
			<Box
				variants={container}
				initial='hidden'
				animate='show'
				exit={{
					opacity:0, transition:{duration: 0.5}
				}}
			>
				  <Container>
            <LogoComponent/>
            <SocialIcons/>
            <Dev />
            </Container>
            <Canvas gl={{ antialias: false }} dpr={[1, 1.5]} style={{height:"100vh"}}>
            <Suspense fallback={null}>
              <ScrollControls infinite horizontal damping={4} pages={4} distance={1}>
                  <Scroll>
                      <Pages />
                  </Scroll>
                  <Scroll html>
                  <div style={{ position: 'absolute', top: '20vh', left: '-90vw', width: 350 }}>
                    <h1 style={{fontSize: 50, paddingBottom: 30}}>Who we are</h1>
                    <br />
                    <p style={{fontSize: 15}}>We do not inherit the land from our ancestors, we borrow it from our children. So, when we are no more, where will the world be? And what will our children inherit? The question is all the more important as the impact of waste on our world is increasingly felt. IOPI or Input Output for a Proper Impact</p>
                    <p style={{fontSize: 15}}>Is a startup that was created during Orange Summer Challenge 2022, it aims to reduce the negative impacts of waste by recovering it.</p>
                  </div>
                  <div style={{ position: 'absolute', top: '20vh', left: '30vw', width: 350 }}>
                    <h1 style={{fontSize: 50, paddingBottom: 30}}>What is our goal</h1>
                    <br />
                    <p style={{fontSize: 15}}>IOPI, or Input/Output for a Proper Impact, is on a mission to raise awareness by initiating the "Clean Tech" movement. IOPI is committed to developing appropriate technological solutions to achieve a more appropriate impact on the environment and society.</p>
                    <p style={{fontSize: 15}}>Within this framework, IOPI's first project concerns the valorization of urine. Two major problems have been raised: on the one hand, the frequent electricity cuts and the increase in fuel prices, and on the other hand, the corridors of the cities which are covered by urine creating nauseating odors. To solve these problems, IOPI has developed a generator that produces electricity from urine, a resource that is available in abundance but not yet exploited to its full potential.</p>
                  </div>
                  <div style={{ position: 'absolute', top: '20vh', left: '130vw', width: 350 }}>
                    <h1 style={{fontSize: 50, paddingBottom: 30}}>What our vision is</h1>
                    <br />
                    <p style={{fontSize: 15}}>
                        In order to create a bright future for future generations, we must focus our efforts on the present. IOPI, through the actions it is currently undertaking, is helping to build a future where waste will be recovered and have much less negative impact on the environment. Hence the slogan \"IOPI towards a clean and sustainable future.
                    </p>
                  </div>
                  <div style={{ position: 'absolute', top: '20vh', left: '230vw', width: 350 }}>
                    <h1 style={{fontSize: 50, paddingBottom: 30}}>Who we are</h1>
                    <br />
                    <p style={{fontSize: 15}}>We do not inherit the land from our ancestors, we borrow it from our children. So, when we are no more, where will the world be? And what will our children inherit? The question is all the more important as the impact of waste on our world is increasingly felt. IOPI or Input Output for a Proper Impact</p>
                    <p style={{fontSize: 15}}>Is a startup that was created during Orange Summer Challenge 2022, it aims to reduce the negative impacts of waste by recovering it.</p>
                  </div>
                  <div style={{ position: 'absolute', top: '20vh', left: '330vw', width: 350 }}>
                    <h1 style={{fontSize: 50, paddingBottom: 30}}>What is our goal</h1>
                    <br />
                    <p style={{fontSize: 15}}>IOPI, or Input/Output for a Proper Impact, is on a mission to raise awareness by initiating the "Clean Tech" movement. IOPI is committed to developing appropriate technological solutions to achieve a more appropriate impact on the environment and society.</p>
                    <p style={{fontSize: 15}}>Within this framework, IOPI's first project concerns the valorization of urine. Two major problems have been raised: on the one hand, the frequent electricity cuts and the increase in fuel prices, and on the other hand, the corridors of the cities which are covered by urine creating nauseating odors. To solve these problems, IOPI has developed a generator that produces electricity from urine, a resource that is available in abundance but not yet exploited to its full potential.</p>
                  </div>
                  <div style={{ position: 'absolute', top: '20vh', left: '430vw', width: 350 }}>
                    <h1 style={{fontSize: 50, paddingBottom: 30}}>What our vision is</h1>
                    <br />
                    <p style={{fontSize: 15}}>
                        In order to create a bright future for future generations, we must focus our efforts on the present. IOPI, through the actions it is currently undertaking, is helping to build a future where waste will be recovered and have much less negative impact on the environment. Hence the slogan \"IOPI towards a clean and sustainable future.
                    </p>
                  </div>
                </Scroll>
              </ScrollControls>
              <Preload />
            </Suspense>
          </Canvas>
        	</Box>
        </ThemeProvider>
    )
}

export default EquipePage
