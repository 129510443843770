import React from 'react'
import styled from 'styled-components'
import Me from '../assets/Images/Art.png'
import logoD from '../assets/Images/Logo IOPI RGB W.svg'
import logoL from '../assets/Images/Logo IOPI RGB B.svg'

const Container = styled.div`
    filter: opacity(0.7);
    position: fixed;
    display: flex;
    align-items: center;
	z-index:3;
    left: 1rem;
	bottom: 1.2rem;
`

const Logo = styled.img`
	height: 1.8rem;
	width: auto;
`

const Dev = (props) => {
    return (
        <Container>
            {/* <Logo src={logoD} /> */}
            <span style={{paddingLeft: 5, fontSize: 13}}>
                Caringly ❤ crafted by <a target="_blank" rel="noreferrer" style={{color: "#0088ff", fontWeight: "bold"}} href='https://abioud.polytechnique.mg'>IOPI</a>
            </span>
        </Container>
    )
}

export default Dev
