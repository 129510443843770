import React from 'react';
import { motion } from 'framer-motion'
import DOMPurify from 'dompurify';
import styled from 'styled-components';

const WebDiv = styled.div`
    text-align: justify;
    padding-left: 3rem;
    padding-right: 1rem;
    @media (min-width: 600px){
        padding-left: 0;
    }
    // *{
    //     -ms-overflow-style: none;
    //   }
    //   ::-webkit-scrollbar {
    //     display: none;
    // }

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #0001;
        border-radius: 2.5px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
    background: #fe9955;
    border-radius: 2.5px;
    }
`

function Card2(props) {
    return (
        <div style={{height: "90%", display:"flex", flexDirection: "column", justifyContent:"space-between", maxWidth: 500, backgroundColor:"transparent"}}>
            <div 
                style={{
                    paddingTop: '6rem'
                }}
            >
                <br/>
                <motion.div
                    initial={{filter:"opacity(0)"}}
                    animate={{filter:"opacity(1)"}}
                    transition={{type:'spring', duration:1, delay:1}}
                >
                    {
                        (props.description)?(
                            <WebDiv style={{maxHeight: "60vh", textShadow:"0 0 15px #fff", fontSize: 19, textAlign: "justify", color:"black", overflowY: "scroll"}} dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(props.description)} } />
                            ):(
                                <></>
                            )
                    }
                    <br></br>
                </motion.div>
            </div>
            <p style={{paddingLeft: "2rem", textShadow:"0 0 15px #fff"}}>
                {
                    (props.tags !== undefined)?(
                        props.tags.map((value) => {
                            return(
                                <span>
                                    {"#"+value+" "}
                                </span>
                            )
                        })
                    ):(
                        <></>
                    )
                }
            </p>
        </div>
    )
}

export default Card2;