import { Route, Routes, useLocation } from "react-router"
import { ThemeProvider } from "styled-components"
import { lightTheme } from "./components/Themes"
import GlobalStyle from "./globalStyles"
import NavIcons from './subComponents/NavIcons'

import { useEffect, useState } from "react"

// Components
import Main from './views/Main';
import AboutPage from './views/AboutPage';
import BlogPage from './views/BlogPage';
import BlogDetail from "./views/BlogDetail"
import Course from "./views/Course"
import EquipePage from './views/EquipePage';
import ProduitPage from './views/ProduitPage';
import ProductNext from "./views/ProductNext"
import Iopi from "./views/Iopi"
import { AnimatePresence } from "framer-motion";
// import SoundBar from "./subComponents/SoundBar";

import GoogleBtn from "./subComponents/GoogleBtn"
import GoogleLogOut from "./subComponents/GoogleLogOut"
import { gapi } from "gapi-script"
import SoundBar from "./subComponents/SoundBar"

function App() {

  const location = useLocation();
  return(
    <>
      <GlobalStyle />
      <ThemeProvider theme={lightTheme}>
        <NavIcons />
        {/* <GoogleBtn/> */}
        <SoundBar />
        {/* For framer-motion animation on page change! */}
        <AnimatePresence exitBeforeEnter>
          <Routes  location={location} key={location.pathname}>
            <Route exact path="/" element={<Main/>}/>
            <Route path="/about" element={<AboutPage/>}/>
            <Route exact path="/blog" element={<BlogPage/>}/>
            <Route exact path={`/blog/:id`} element={<BlogDetail/>}/>
            <Route exact path="/course" element={<Course/>}/>
            <Route exact path="/equipe" element={<Iopi/>}/>
            <Route exact path="/produits" element={<ProduitPage/>}/>
            <Route exact path={`/product/:id`} element={<ProductNext/>}/>
          </Routes>
        </AnimatePresence>
      </ThemeProvider>
    </>
  ) 
    
}

export default App

