import React, {useEffect, useState} from 'react';
import Card2 from '../subComponents/Card2';
import Thumbnail from '../subComponents/Thumbnail';
import styled from 'styled-components'
import { motion } from 'framer-motion'
import BigTitlte from '../subComponents/BigTitlte';
import LogoComponent from '../subComponents/LogoComponent';
import Dev from '../subComponents/Dev';
import SocialIcons from '../subComponents/SocialIcons';
import { AboutData } from '../data/AboutData';

const MainContainer = styled(motion.div)`
    background: ${props => props.theme.body};
    width: 100vw;
    height: 100vh;
    overflow:hidden;
    position: relative;
    h2,h3,h4,h5,h6{
        font-family:'Karla', sans-serif ;
        font-weight:500;
    }
`
const ALink = styled.a`
    display: flex;
    justify-content: end;
`

const SideBar = styled.div`
    display: none;

    @media (min-width: 900px){
        display: block;
        background-color: black;
        width: 50vw
    }
`

const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,
  
      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }
  }


const Container = styled.div`
    padding: 0rem;
    display: flex;
`

function AboutPage(props) {
    const [id, setId] = useState(1);
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(AboutData[(id-1)])
    }, [id]);

    return (
        <MainContainer
            variants={container}
            initial='hidden'
            animate='show'
            exit={{
                opacity:0, transition:{duration: 0.5}
            }}
        >
            <Container>
                {/* <BigTitlte text={"A propos"} top="40vh" right="8rem" color="#0002"/> */}
                <LogoComponent theme="dark"/>
                {/* <NavIcons /> */}
                <SocialIcons />
                <Dev />

                {
                (data !== undefined && data !== [])?(
                    <div style={{
                        background: "transparent",
                        height: "100vh",
                        width: "100vw"
                    }}>
                        <div
                            style={{
                                height: "100%", 
                                width: "100%", 
                                display: "flex", 
                                alignItems: "center",
                                justifyContent: "center",
                            }}>
                            <SideBar>
                                <motion.div
                                    initial={{filter:"opacity(0)"}}
                                    animate={{filter:"opacity(1)"}}
                                    transition={{type:'ease', duration:1, delay:1.4}}
                                >
                                    <Thumbnail
                                        image={data.image}
                                        title={data.title}
                                    />
                                    <div 
                                        style={{
                                            position: "fixed",
                                            width: "calc(50vw - 8rem)",
                                            bottom: "6rem",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        {
                                            AboutData.map((item, index) => {
                                                return(
                                                    <div
                                                        onClick={()=>{
                                                            setId(item.id)
                                                        }}
                                                    >
                                                        <ALink
                                                            href="#"
                                                        >
                                                            <h1
                                                                style={{
                                                                    color: "#fff",
                                                                    paddingBottom: 10,
                                                                    textAlign: "right"
                                                                }}
                                                                >
                                                                {item.title} 
                                                            </h1>
                                                            <h1 
                                                                style={{
                                                                    color: "#fff5",
                                                                    fontWeight: "lighter"
                                                                }}
                                                            >
                                                                &nbsp;|&nbsp;
                                                                <span
                                                                    style={{
                                                                        color: (id === item.id)?("#fe9955"):("#fff5")
                                                                    }}
                                                                    >
                                                                    {item.id}
                                                                </span>
                                                            </h1>
                                                        </ALink>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </motion.div>
                            </SideBar>
                            <motion.div
                                style={{
                                    height: "100%",
                                    width: "50vw",
                                    paddingLeft: "9vw",
                                    paddingRight: "9vw",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}
                            >
                                <Card2
                                    title={data.title}
                                    description={data.description}
                                    tags={data.tags}
                                />
                            </motion.div>
                        </div>
                    </div>
                ):(
                    <></>
                )
            }
            </Container>
            {
                (data.id !== undefined)?(
                    <>
                        <BigTitlte text={"#"+data.id} top="6rem" left="2rem" color="#fff2" />
                    </>
                ):(
                    <></>
                )
            }
        </MainContainer>

    )
}

export default AboutPage;