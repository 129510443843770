import React, { useState, useRef } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {lightTheme} from '../components/Themes';
import LogoComponent from '../subComponents/LogoComponent';
import SocialIcons from '../subComponents/SocialIcons';
import { motion } from 'framer-motion'
import ParticleComponent from '../subComponents/ParticleComponent';
import Dev2 from '../subComponents/Dev2';
import BigTitle from '../subComponents/BigTitlte'
import {IoAdd, IoEye, IoRemove} from 'react-icons/io5';
import Product3 from '../assets/Images/Product3.png'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import APP from "../assets/Images/App.png"
import DESK from "../assets/Images/Desk.png"
import { useSelector } from 'react-redux';
import IntlTelInput from 'react-intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'react-intl-tel-input/dist/main.css';
import { postOrderToApi } from '../api/api';

const Box = styled(motion.div)`
    background-color: ${props => props.theme.body};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 8rem;
    padding-bottom: 8rem;
`

const Offre = styled.div`
    width: 250px;

    @media (min-width: 900px){
        width: 300px;
    }

    @media (min-width: 1366px){
        width: 350px;
    }
`
const OffreCenter = styled.div`
    background-color: #fffd;
    border-radius: 10px;
    width: calc(350px - 0.1*350px);
    // width: 350px;
    box-shadow: 0 15px 15px #0001;
    overflow: hidden;
    transform: scale(1.1);
`

const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,

      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }

  }

const ProduitPage = () => {

    const user = useSelector((state) => state.user.value)

    const [request, setrequest] = useState([])

    const [quantity, setquantity] = useState(1)
    
    const [rawValue, setRawValue] = useState(undefined)

    const [formattedValue, setFormattedValue] = useState(undefined)

    const [product, setProduct] = useState(undefined)

    const [show1, setshow1] = useState(false)

    const handleClose1 = () => {
        setshow1(false)
    }
    const handleShow1 = () => {
        setshow1(true)
        console.log("show1")
    }
    const sendRequest = () => {
        setshow1(false)
        submit()
    }

    const qty = useRef(null)
    const message = useRef(null)
    const firstname = useRef(null)
    const lastname = useRef(null)
    const email = useRef(null)

    const submit = () => {

        var phonenumber = formattedValue
        phonenumber = phonenumber.substring(1)
        const re = /\s/gi;
        phonenumber = phonenumber.replace(re, "")

        var data = {
            // "token" : user.tokenObj.access_token,
            "firstname" : firstname.current.value,
            "lastname" : lastname.current.value,
            "phonenumber" : phonenumber,
            "email" : email.current.value,
            "product" : product,
            "qty" : quantity,
            "message" : message.current.value
        }

        setquantity(1)

        console.log(data)

        postOrderToApi(data)
    }

    return (
        <ThemeProvider theme={lightTheme}>
            {/* <BigTitle text="Produits" top="80%" right="30%" color="#0002"/> */}
            <Box
                variants={container}
                initial='hidden'
                animate='show'
                exit={{
                    opacity:0, transition:{duration: 0.5}
                }}
            >
                <LogoComponent theme='light'/>
                <SocialIcons theme='light'/>
                <Dev2 />
                {/* <ParticleComponent theme="light"/> */}
                {/* <div
                        style={{
                            height: "75vh",
                            width: "100%",
                            backgroundImage: `url(${Product3})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            marginBottom: 40
                        }}
                    >

                </div> */}
                <div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            backgroundColor: "#eee",
                            backgroundImage: `linear-gradient(to bottom, #fff4, white), url(${Product3})`,
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            borderRadius: 10,
                            flexWrap: "wrap"
                        }}
                    >
                        <Offre>
                            <div
                                style={{
                                    padding: "3rem"
                                }}
                            >
                                <h1>
                                    Basic
                                </h1>
                                <h1 style={{width: "100%", color: "tomato"}}>
                                    {/* <span style={{fontSize: 20}}>Starting at</span>
                                    <br /> */}
                                    XXX &euro;
                                </h1>
                                <p>
                                    Ideal for homes, small restaurants, etc.
                                </p>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Button
                                        style={{
                                            display: "flex",
                                            flex: 3,
                                            cursor: "pointer",
                                            padding: 9,
                                            borderRadius: 5,
                                            backgroundColor: "#70AD47",
                                            marginRight: 10,
                                            justifyContent: "center",
                                            marginTop: 10,
                                            marginBottom: 10,
                                            borderWidth: 0
                                        }}
                                        onClick={()=>{
                                            handleShow1()
                                            setProduct("BASIC")
                                            }
                                        }
                                    >
                                        <span
                                            style={{
                                                color: "white",
                                            }}
                                        >
                                            Order
                                        </span>
                                    </Button>

                                    <Modal show={show1} onHide={handleClose1} centered>
                                        <Modal.Header closeButton>
                                        <Modal.Title>Order - {product}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Firstname</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Firstname"
                                                        defaultValue={(user)?(user.profileObj.givenName):("")}
                                                        ref={firstname}
                                                        autoFocus
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                                    <Form.Label>Lastname</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Lastname"
                                                        defaultValue={(user)?(user.profileObj.familyName):("")}
                                                        ref={lastname}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
                                                    <Form.Label>Email address</Form.Label>
                                                    <Form.Control
                                                        type="email"
                                                        placeholder="name@example.com"
                                                        defaultValue={(user)?(user.profileObj.email):("")}
                                                        ref={email}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                                    <Form.Label>Phone number</Form.Label>
                                                    <div>
                                                        <IntlTelInput
                                                            placeholder="032 XX XXX XX"
                                                            containerClassName="intl-tel-input"
                                                            inputClassName="form-control"
                                                            preferredCountries={['mg']}
                                                        //   utilsScript={libPhoneNumber}
                                                            onPhoneNumberChange={(rawValue, formattedValue)=>{
                                                                setFormattedValue(formattedValue)
                                                                setRawValue(rawValue)
                                                            }}
                                                            onPhoneNumberBlur={(rawValue, formattedValue)=>{
                                                                setFormattedValue(formattedValue)
                                                                setRawValue(rawValue)
                                                            }}
                                                            format
                                                        />
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                                                    <Form.Label>Quantity</Form.Label>
                                                    {/* <InputSpinner
                                                        type={'real'}
                                                        precision={1}
                                                        max={10}
                                                        min={0}
                                                        step={1}
                                                        value={1}
                                                        onChange={num=>console.log(num)}
                                                        variant={'dark'}
                                                        size="sm"
                                                        ref={qty}
                                                    /> */}
                                                    <div
                                                        style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%'}}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                cursor: "pointer",
                                                                padding: 5,
                                                                borderRadius: 5,
                                                                backgroundColor: "#70AD47",
                                                                marginRight: 10,
                                                                justifyContent: "center",
                                                                marginTop: 10,
                                                                marginBottom: 10,
                                                            }}
                                                            onClick={()=>{
                                                                if(quantity > 1){
                                                                    setquantity(quantity-1)
                                                                }
                                                            }}
                                                        >
                                                            <IoRemove
                                                                size={33}
                                                                color={ "white" }
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: 100,
                                                                borderRadius: 7,
                                                                // boxShadow: '0 3px 7px #0005',
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                padding: 10,
                                                                marginLeft: 5,
                                                                marginRight: 5
                                                            }} 
                                                            ref={qty}
                                                        >
                                                            {quantity}
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                cursor: "pointer",
                                                                padding: 5,
                                                                borderRadius: 5,
                                                                backgroundColor: "#70AD47",
                                                                marginRight: 10,
                                                                justifyContent: "center",
                                                                marginTop: 10,
                                                                marginBottom: 10,
                                                            }}
                                                            onClick={()=>{
                                                                if(quantity < 10){
                                                                    setquantity(quantity+1)
                                                                }
                                                            }}
                                                        >
                                                            <IoAdd
                                                                size={33}
                                                                color={ "white" }
                                                            />
                                                        </div>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group
                                                    className="mb-3"
                                                    controlId="exampleForm.ControlTextarea1"
                                                >
                                                    <Form.Label>Message</Form.Label>
                                                    <Form.Control as="textarea" rows={3} ref={message} />
                                                </Form.Group>
                                            </Form>
                                        </Modal.Body>
                                        <Modal.Footer>
                                        <Button variant="secondary" onClick={handleClose1}>
                                            Close
                                        </Button>
                                        <Button variant="primary" onClick={sendRequest}>
                                            Submit
                                        </Button>
                                        </Modal.Footer>
                                    </Modal>

                                    <div
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            cursor: "pointer",
                                            padding: 5,
                                            borderRadius: 5,
                                            backgroundColor: "#70AD47",
                                            marginRight: 10,
                                            justifyContent: "center",
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                    >
                                        <IoEye
                                            size={33}
                                            color={ "white" }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <br></br>
                                    <ul>
                                        <li>
                                            One stacks of 39 cells.
                                        </li>
                                        <li>
                                            A power pack.
                                            <ul>
                                                <li>
                                                    4 USB ports for recharging peripherals
                                                </li>
                                                <li>
                                                    2 terminal blocks for lighting
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            One small urine tank.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Offre>
                        <OffreCenter>
                            <div
                                style={{
                                    backgroundColor: "#70AD47",
                                    height: 20,
                                }}
                            >
                            </div>
                            <div
                                style={{
                                    padding: "3rem"
                                }}
                            >
                                <h1>
                                    Regular
                                </h1>
                                <h1 style={{width: "100%", color: "tomato"}}>
                                    {/* <span style={{fontSize: 20}}>Starting at</span>
                                    <br /> */}
                                    XXX &euro;
                                </h1>
                                <p>
                                    Residence, Family, Restaurant, etc.
                                </p>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Button
                                        style={{
                                            display: "flex",
                                            flex: 3,
                                            cursor: "pointer",
                                            padding: 9,
                                            borderRadius: 5,
                                            backgroundColor: "#f5557D",
                                            marginRight: 10,
                                            justifyContent: "center",
                                            marginTop: 10,
                                            marginBottom: 10,
                                            borderWidth: 0
                                        }}
                                        onClick={()=>{
                                            handleShow1()
                                            setProduct("REGULAR")
                                            }
                                        }
                                    >
                                        <span
                                            style={{
                                                color: "white",
                                            }}
                                        >
                                            Order
                                        </span>
                                    </Button>
                                    <div
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            cursor: "pointer",
                                            padding: 5,
                                            borderRadius: 5,
                                            backgroundColor: "#70AD47",
                                            marginRight: 10,
                                            justifyContent: "center",
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                    >
                                        <IoEye
                                            size={33}
                                            color={ "white" }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <br></br>
                                    <ul>
                                        <li>
                                            One stacks of 39 cells.
                                        </li>
                                        <li>
                                            One power pack:
                                            <ul>
                                                <li>
                                                    4 USB ports for recharging peripherals.
                                                </li>
                                                <li>
                                                    2 terminal blocks for lighting.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            One medium urine tank.
                                        </li>
                                        <li>
                                            One urinal cabin.
                                        </li>
                                        <li>
                                            A real time control software.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </OffreCenter>
                        <Offre>
                            <div
                                style={{
                                    padding: "3rem"
                                }}
                            >
                                <h1>
                                    Plus
                                </h1>
                                <h1 style={{width: "100%", color: "tomato"}}>
                                    {/* <span style={{fontSize: 20}}>Starting at</span>
                                    <br /> */}
                                    XXX &euro;
                                </h1>
                                <p>
                                    This solution cover larger area such as school, university, etc.
                                </p>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Button
                                        style={{
                                            display: "flex",
                                            flex: 3,
                                            cursor: "pointer",
                                            padding: 9,
                                            borderRadius: 5,
                                            backgroundColor: "#70AD47",
                                            marginRight: 10,
                                            justifyContent: "center",
                                            marginTop: 10,
                                            marginBottom: 10,
                                            borderWidth: 0
                                        }}
                                        onClick={()=>{
                                            handleShow1()
                                            setProduct("PLUS")
                                            }
                                        }
                                    >
                                        <span
                                            style={{
                                                color: "white",
                                            }}
                                        >
                                            Order
                                        </span>
                                    </Button>

                                    <div
                                        style={{
                                            display: "flex",
                                            flex: 1,
                                            cursor: "pointer",
                                            padding: 5,
                                            borderRadius: 5,
                                            backgroundColor: "#70AD47",
                                            marginRight: 10,
                                            justifyContent: "center",
                                            marginTop: 10,
                                            marginBottom: 10,
                                        }}
                                    >
                                        <IoEye
                                            size={33}
                                            color={ "white" }
                                        />
                                    </div>
                                </div>
                                <div>
                                    <br></br>
                                    <ul>
                                        <li>
                                            One stacks of 39 cells.
                                        </li>
                                        <li>
                                            One (scalable) power pack:
                                            <ul>
                                                <li>
                                                    Multiple USB ports for peripherals.
                                                </li>
                                                <li>
                                                    Multiple terminal blocks for lighting.
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            One large urine tank.
                                        </li>
                                        <li>
                                            One urinal cabin.
                                        </li>
                                        <li>
                                            A real time control software.
                                        </li>
                                        <li>
                                            A urinal cabin with a personalized design
                                        </li>
                                        <li>
                                            The possibility to adapt the number of cells in the stacks
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Offre>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}
                >
                    {/* <img style={{width: "650px", height: "100%", boxShadow: "0 0 20px #0007", borderRadius: 20}} src={DESK} alt="IOPI DESK"/> */}
                    <img style={{width: "700px", height: "100%"}} src={APP} alt="IOPI APP"/>
                </div>
            </Box>
        </ThemeProvider>
    )
}

export default ProduitPage
