import { motion } from 'framer-motion'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'


const Box = styled(motion(NavLink))`
    width: calc(16rem + 5vw);
    text-decoration: none;
    height: 20rem;
    padding: 1rem;
    margin-bottom: 1rem;
    color: ${props => props.theme.text};
    background-color: white;
    box-shadow:0 0 1rem 0 rgba(0,0,0,0.2);
    cursor: pointer;

    display: flex;
    flex-direction: column;
    z-index:5;

    &:hover{
        color:${props => props.theme.body};
        background-color: #9251ac;
        transition: all 0.3s ease;
    }
`

const Image = styled.div`
    background-image: ${props => `url(${props.img})`};
    width:100%;
    height: 60%;
    background-size: cover;
    border: 1px solid transparent;
    background-position: center center;

    ${Box}:hover &{
        border:1px solid ${props => props.theme.body};
    }
`
    const Title = styled.h5`
    color: inherit;
    padding: 0.5rem 0;
    padding-top:1rem;
    font-family: 'Karla',sans-serif;
    font-weight:700;
    border-bottom: 1px solid ${props => props.theme.text};

    ${Box}:hover &{
        border-bottom: 1px solid ${props => props.theme.body};
    }
`
const HashTags = styled.div`
    padding: 0.5rem 0;
`
const Tag= styled.span`
    padding-right: 0.5rem;
`
const Date = styled.span`
    padding:0.5rem 0
`

const Container = styled(motion.div)``;

// Framer motion configuration
const Item = {
    hidden:{
        scale:0.95,
        filter: "opacity(0)"
    },
    show:{
        scale:1,
        filter: "opacity(1)",
        transition: {
            type: 'ease',
            duration: 2
        }
    }
}

const BlogComponent = (props) => {
    const {title, tags, created_at, image, id} = props.blog;
    return (
        <Container
            variants={Item}
        >
            <Box to={"/blog/"+id}>
                <Image img={image} />
                <Title>{title}</Title>
                <HashTags>
                    {
                        tags.map((t,id) => {
                            return <Tag key={id}>#{t}</Tag>
                        })
                    }
                </HashTags>
                <Date>
                    {created_at}
                </Date>
            </Box>
        </Container>
    )
}

export default BlogComponent
