import React, { useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {lightTheme} from '../components/Themes';
import LogoComponent from '../subComponents/LogoComponent';
import Dev2 from '../subComponents/Dev2';
import { motion } from 'framer-motion'
import '../assets/customCss/blog.css'
import { IoArrowBack } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Blogs } from '../data/BlogData';
import DOMPurify from 'dompurify';
import audio from '../assets/audio/Chem.aac'

const Box = styled(motion.div)`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 9rem;
    padding-bottom: 5rem;
`

const WebDiv = styled.div`
    text-align: justify;
    padding-top: 3rem;
    padding-left: 3.5rem;
    @media (min-width: 600px){
        padding-left: 0;
    }
    
    h1,
    h2,
    h3,
    h4 {
        word-break: break-all;
    }
`

const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,
  
      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }
  }

const BlogDetail = () => {
    const { id } = useParams("id");
    const [data, setData] = useState([])

    useEffect(() => {
        window.scrollTo(0,0)
        if(id){
            setData(Blogs[id])
        }
    }, [id]);
    
    console.log(data.data)

    return (
        <ThemeProvider theme={lightTheme}>
            <Box
                variants={container}
                initial='hidden'
                animate='show'
                exit={{
                    opacity:0, transition:{duration: 0.5}
                }}
            >
                <LogoComponent theme='light'/>
                <Dev2 />
                <div
                    style={{
                        maxWidth: 800,
                        paddingLeft: 20,
                        paddingRight: 20
                        }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}
                    >
                        <NavLink
                            to="/blog"
                            style={{
                                paddingLeft: 0,
                                paddingRight: 20,
                            }}
                        >
                            <IoArrowBack
                                size={30}
                                color="#888"
                            />
                        </NavLink>
                        <h4
                            style={{fontSize: "calc(40px + 0.5vw)", fontWeight: "bolder", wordBreak: "break-all"}}
                        >
                            {data.title}
                        </h4>
                    </div>
                    <br />
                    {
                        (data.audio)?(
                            <div 
                                style={{
                                    marginTop: 10,
                                    margingBottm: 10,
                                }}
                            >
                                <audio
                                    style={{
                                        width: "100%",
                                    }}
                                    controls
                                    preload='metadata'
                                    src={audio}>
                                        Your browser does not support the
                                        <code>audio</code> element.
                                </audio>
                            </div>
                        ):(
                            <></>
                        )
                    }
                        <p 
                            style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                            }}
                        >
                            {/* Author: {data.author} | Voix: {data.voice} | Date: {data.created_at} */}
                            Author: {data.author} | Date: {data.created_at}
                        </p>
                    <hr />
                    {
                        (data.body)?(
                            <WebDiv dangerouslySetInnerHTML={ {__html: DOMPurify.sanitize(data.body)} } />
                        ):(
                            <></>
                        )
                    }

                </div>
            </Box>
        </ThemeProvider>
    )
}

export default BlogDetail
