import React from 'react'
import styled from 'styled-components'
import logoD from '../assets/Images/Logo IOPI RGB W.svg'
import logoL from '../assets/Images/Logo IOPI RGB B.svg'

const Logo = styled.img`
	position: fixed;
	left: 1.5rem;
	top: 1.5rem;
	z-index:3;
`

const LogoComponent = (props) => {
    return (
        <Logo 
			src={(props.theme === "dark")?(logoD):(logoL)}
			style={{
				height: "auto",
				width: 120
			}}
		/>
    )
}

export default LogoComponent
