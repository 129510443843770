import { motion } from 'framer-motion'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { Facebook, YouTube } from '../components/AllSvgs'
import {DarkTheme} from '../components/Themes'


const Icons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    bottom: 0;
    padding-bottom: 10px;
    right: 1.5rem;
    z-index:3;
    &>*:not(:last-child){
        margin: 0.5rem 0;
    }
`

const Line = styled(motion.span)`
    width: 2px;
    height: 4.5rem;
    background-color: ${props => props.color === 'dark' ? DarkTheme.text : DarkTheme.body  };
`

const SocialIcons = (props) => {
    return (
        <Icons>
            {/* <Line color={props.theme}
                initial={
                    {
                        height:0
                    }
                }
                animate={{
                    height: '4.5rem'
                }}
                transition={{
                    type:'spring', duration:1, delay:0.8
                }}
            /> */}
            <motion.div
                initial={{transform:"scale(0)"}}
                animate={{scale:[0,1,1.5,1]}}
                transition={{type:'spring', duration:1, delay:1.4}}
                style={{
                    paddingRight: 15
                }}
            >
                <a href="https://web.facebook.com/profile.php?id=100083981363329" style={{color:'inherit'}} target="_blank" rel="noopener noreferrer">
                    <Facebook width={25} height={25} fill={"#07f"} />
                </a>
            </motion.div>
            <motion.div
                initial={{transform:"scale(0)"}}
                animate={{scale:[0,1,1.5,1]}}
                transition={{type:'spring', duration:1, delay:1.6}}
                style={{
                    paddingRight: 15
                }}
            >
                <a href="https://www.youtube.com/channel/UCww2egoJ532ezKoXJrS1Fcg" style={{color:'inherit'}} target="_blank" rel="noopener noreferrer">
                    <YouTube width={25} height={25} fill={"#f30"} />
                </a>
            </motion.div>
        </Icons>
    )
}

export default SocialIcons
