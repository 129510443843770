import React from 'react';
import styled from 'styled-components'
// import img from "../assets/Images/patrick-tomasso-Oaqk7qqNh_c-unsplash.jpg"

// const Image = styled.img`
//     height: 100vh;
//     width: auto;
//     transition: all 1s ease;
// `

const Image2 = styled.div`
    background-image: url("${props => props.src}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width: 50vw;
    transition: background-image 0.5s ease;
`

function Thumbnail(props) {
    return(
        <div style={{display: "flex", overflow: "hidden", width: "50vw"}}>
            {/* <Image className="tiles" src={props.image} style={{filter: "contrast(75%) brightness(0.25)"}} alt={props.title}/> */}
            <Image2 className="tiles" src={(props.image !== null && props.image !== undefined)?(props.image.default):("")} style={{filter: "contrast(75%) brightness(0.35)"}}/>
        </div>
    )
}

export default Thumbnail;