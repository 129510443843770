import { createGlobalStyle } from "styled-components";
import './index.css'




const GlobalStyle = createGlobalStyle`

*,*::before,*::after,h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}
h1,h2,h3,h4,h5,h6{
    display: inline-block;
    font-family: 'Baloo 2'
}

p,
a,
span {
    font-family: 'Comfortaa';
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: 'Comfortaa','Source Sans Pro',sans-serif;
}

`

export default GlobalStyle;