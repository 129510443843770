import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import img from "../assets/Images/patrick-tomasso-Oaqk7qqNh_c-unsplash.jpg"
import LogoComponent from '../subComponents/LogoComponent'
import SocialIcons  from '../subComponents/SocialIcons'
import { Courses } from '../data/CoursesData'
import CourseComponent from '../components/CourseComponent'
import BigTitle from "../subComponents/BigTitlte"
import Dev from '../subComponents/Dev'
import { motion } from 'framer-motion'
import { getApiBlogs } from '../api/api'
import '../assets/customCss/extra.css'


const MainContainer = styled(motion.div)`
    background-image: url(${img});
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    height: 100vh;
    width: 100vw;
    overflow-y: scroll
`
const Container = styled.div`
    background-color: ${props => `rgba(${props.theme.bodyRgba},0.8)`};
    width: 100%;
    height:100%;

    position: relative;
`

const Center = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8rem;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-gap: calc(1rem + 2vw);

    @media (min-width: 600px){
        grid-template-columns: auto auto;
    }

    @media (min-width: 900px){
        grid-template-columns: auto auto auto;
    }
`

// Framer-motion config
const container = {

    hidden: {opacity:0},
    show: {
      opacity:1,
  
      transition:{
        staggerChildren: 0.2,
        duration: 0.5,
      }
    }
  
  }

const Course = () => {

    //const [numbers, setNumbers] = useState(0);
    const [apiBlogsData, setApiBlogsData] = useState([]);

    useEffect(() => {
        window.scrollTo(0,0)
        getApiBlogs().then((data)=>{
            if(data){
				setApiBlogsData(data)
            }
        })
    }, [])


    return (
        <MainContainer
            variants={container}
            initial='hidden'
            animate='show'
            exit={{
                opacity:0, transition:{duration: 0.5}
            }}
        >
            <Container>
                <LogoComponent />
                {/* <PowerButton /> */}
                <SocialIcons />
                <Dev />
                {/* <AnchorComponent number={numbers}/> */}
                <Center>
                    <Grid>
                    {
                        (apiBlogsData > 0)?(
                            apiBlogsData.map(blog => {
                                return (
                                    <CourseComponent key={blog.id} blog={blog} />
                                )
                            })
                        ):(
                            Courses.map(blog => {
                                return (
                                    <CourseComponent key={blog.id} blog={blog} />
                                )
                            })
                        )
                    }
                    </Grid>
                </Center>
                <BigTitle text="Cours" top="5rem" left="5rem" color="#0002"/>
            </Container>
        </MainContainer>
    )
}

export default Course
