// about data

export const AboutData = [
    {
        id:1,
        next:2,
        prev:2,
        title:"IOPI?",
        description:`
            <h1>IOPI?</h1>
            <br/>
            <br/>
            <p>
            What if waste could have a positive impact on the future? It seems absurd at first, but after reflection, are not these wastes present in abundance in our environment forms of untapped resources? IOPI or Input/Output for a Proper Impact is a start-up that mobilizes its efforts in the implementation of technologies to exploit the potential of this waste. Through the actions it undertakes, IOPI promotes the idea of clean technology, for a more appropriate impact on the environment and society.

            </p>
            <br/>
            <p>Contact us!</p>
            <ul>
                <li>
                    +261 32 62 729 24
                </li>
                <li>
                    contact@iopi.mg
                </li>
                <li>
                    iopimada@gmail.com
                </li>
            </ul>
            <p>
            IOPI uses several channels (Video, Audio, Printed Book, etc.) to share knowledge and initiate the clean technology movement 
            </p>
            <p>Liens:</p>
            <ul>
                <li>
                    <a href='https://web.facebook.com/profile.php?id=100083981363329'>Facebook</a>
                </li>
                <li>
                    <a href=''>Instagram</a>
                </li>
                <li>
                    <a href='https://www.youtube.com/channel/UCww2egoJ532ezKoXJrS1Fcg'>Youtube</a>
                </li>
                <li>
                    <a href=''>Google Podcast</a>
                </li>
                <li>
                    <a href=''>Application mobile</a>
                </li>
            </ul>

        `,
        tags:["STEM", "Industrie","Innovation","environment","technology"],
        image: require("../assets/Images/Team/Equipe.JPG"),
    },
    {
        id:2,
        next:1,
        prev:1,
        title:"Terms Of Use",
        description:`
            <div>
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">terms and condition</font></font></h1>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Last updated: September 30, 2022</font></font></p>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Please read these terms and conditions carefully before using our service.</font></font></p>
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Interpretation and definitions</font></font></h1>
                <h2><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Interpretation</font></font></h2>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Words whose initial letter is capitalized have meanings defined in the following conditions. </font><font style="vertical-align: inherit;">The following definitions will have the same meaning whether they appear in the singular or plural.</font></font></p>
                <h2><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Definition</font></font></h2>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">For the purposes of these terms and conditions&nbsp;:</font></font></p>
                <ul>
                <li>
                <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Affiliated</font></font></strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> means an entity that controls, is controlled by, or is under common control with a party, where "control" means ownership of 50 &nbsp;%  or more of the shares, interests, or other securities entitled to vote for the election of directors or other management authority.</font></font></p>
                </li>
                <li>
                <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">The country</font></font></strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> refers to : Madagascar.</font></font></p>
                </li>
                <li>
                <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">The Society</font></font></strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> (hereinafter referred to as "&nbsp;the Company&nbsp;", "&nbsp;We&nbsp;", "&nbsp;Us&nbsp;" or "&nbsp;Our&nbsp;" in this Agreement) refers to IOPI , Ankorondrano, Antananarivo, Madagasikara</font></font></p>
                </li>
                <li>
                <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Device</font></font></strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> means any device that can access the Service such as a computer, cell phone or digital tablet.</font></font></p>
                </li>
                <li>
                <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">The service</font></font></strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> refers to the website.</font></font></p>
                </li>
                <li>
                <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Terms and Conditions</font></font></strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> (also referred to as "&nbsp;Terms & Conditions&nbsp;") means these Terms & Conditions which constitute the entire agreement between you and the Company regarding the use of the Service. </font></font><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> .</font></font></p>
                </li>
                <li>
                <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Third-party social media service</font></font></strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> means any service or content (including data, information, products or services) provided by a third party that may be displayed, included or made available through the Service.
                </font></font></p>
                </li>
                <li>
                <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">the website</font></font></strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> </font></font><a href="http://www.iopi.mg" rel="external nofollow noopener" target="_blank"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">http://www.iopi.mg</font></font></a></p>
                </li>
                <li>
                <p><strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">you</font></font></strong><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> means the person accessing or using the Service, or the corporation or other legal entity on whose behalf such person accesses or uses the Service, as applicable.</font></font></p>
                </li>
                </ul>
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Recognition</font></font></h1>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">These are the terms and conditions governing the use of this service and the agreement that operates between you and the company.
                </font><font style="vertical-align: inherit;">These Terms and Conditions define the rights and obligations of all users regarding the use of the Service.
                </font></font></p>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions.
                </font><font style="vertical-align: inherit;">These Terms and Conditions apply to all visitors, users and others who access or use the Service.</font></font></p>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">By accessing or using the Service, you agree to be bound by these Terms and Conditions. </font><font style="vertical-align: inherit;">If you do not agree with any part of these terms and conditions, you may not access the service.</font></font></p>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">You represent that you are over the age of 18. The Company does not allow anyone under the age of 18 to use the Service.</font></font></p>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Your access to and use of the Service is also conditioned upon Your acceptance of and compliance with the Company's Privacy Policy. </font><font style="vertical-align: inherit;">Our Privacy Policy describes our policies and procedures on the collection, use and disclosure of your personal information when you use the App or the Website and informs you of your privacy rights and how the law protects you. 
                </font><font style="vertical-align: inherit;">Please read our privacy policy carefully before using our service.</font></font></p>
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Links to other websites</font></font></h1>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Notre service peut contenir des liens vers des sites Web ou des services tiers qui ne sont ni détenus ni contrôlés par la société.</font></font></p>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">La Société n'a aucun contrôle sur le contenu, les politiques de confidentialité ou les pratiques des sites Web ou services tiers et n'assume aucune responsabilité à cet égard. </font><font style="vertical-align: inherit;">Vous reconnaissez et acceptez en outre que la Société ne sera pas responsable, directement ou indirectement, de tout dommage ou perte causé ou présumé avoir été causé par ou en relation avec l'utilisation ou la confiance accordée à ces contenus, biens ou services disponibles sur ou par l'intermédiaire de ces sites Web ou services.</font></font></p>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Nous vous conseillons vivement de lire les termes et conditions et les politiques de confidentialité de tous les sites Web ou services tiers que vous visitez.</font></font></p>
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Résiliation</font></font></h1>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Nous pouvons résilier ou suspendre votre accès immédiatement, sans préavis ni responsabilité, pour quelque raison que ce soit, y compris, sans s'y limiter, si vous enfreignez les présentes conditions générales.</font></font></p>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">En cas de résiliation, Votre droit d'utiliser le Service cessera immédiatement.</font></font></p>
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Limitation de responsabilité</font></font></h1>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Nonobstant tout dommage que vous pourriez subir, l'entière responsabilité de la Société et de l'un de ses fournisseurs en vertu de toute disposition des présentes Conditions et votre recours exclusif pour tout ce qui précède seront limités au montant effectivement payé par vous via le Service.</font></font></p>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Dans la mesure maximale permise par la loi applicable, en aucun cas la Société ou ses fournisseurs ne pourront être tenus responsables de tout dommage spécial, accessoire, indirect ou consécutif (y compris, mais sans s'y limiter, les dommages pour manque à gagner, perte de données ou d'autres informations, en cas d'interruption d'activité, de blessure corporelle, de perte de confidentialité résultant de ou liée de quelque manière que ce soit à l'utilisation ou à l'impossibilité d'utiliser le Service, les logiciels tiers et/ou le matériel tiers utilisés avec le Service, ou autrement en relation avec toute disposition des présentes Conditions), même si la Société ou tout fournisseur a été informé de la possibilité de tels dommages et même si le recours ne remplit pas son objectif essentiel.</font></font></p>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Certains États n'autorisent pas l'exclusion des garanties implicites ou la limitation de responsabilité pour les dommages indirects ou consécutifs, ce qui signifie que certaines des limitations ci-dessus peuvent ne pas s'appliquer. </font><font style="vertical-align: inherit;">Dans ces États, la responsabilité de chaque partie sera limitée dans toute la mesure permise par la loi.</font></font></p>
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Avis de non-responsabilité "TEL QUEL" et "TEL QUE DISPONIBLE"</font></font></h1>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Le service vous est fourni "TEL QUEL" et "TEL QUE DISPONIBLE" et avec tous les défauts et défauts sans garantie d'aucune sorte. </font><font style="vertical-align: inherit;">Dans la mesure maximale autorisée par la loi applicable, la Société, en son propre nom et au nom de ses Sociétés affiliées et de ses concédants de licence et fournisseurs de services respectifs, décline expressément toute garantie, expresse, implicite, statutaire ou autre, en ce qui concerne le Service, y compris toutes les garanties implicites de qualité marchande, d'adéquation à un usage particulier, de titre et de non-contrefaçon, et les garanties pouvant découler du cours des transactions, du cours des performances, de l'utilisation ou des pratiques commerciales. </font><font style="vertical-align: inherit;">Sans limitation à ce qui précède, la Société ne fournit aucune garantie ou engagement, et ne fait aucune déclaration d'aucune sorte que le Service répondra à Vos exigences, atteindra les résultats escomptés,</font></font></p>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Sans limiter ce qui précède, ni la Société ni aucun de ses fournisseurs ne fait aucune représentation ou garantie de quelque nature que ce soit, expresse ou implicite&nbsp;: (i) quant au fonctionnement ou à la disponibilité du Service, ou des informations, du contenu, des matériaux ou des produits y compris&nbsp;; </font><font style="vertical-align: inherit;">(ii) que le Service sera ininterrompu ou sans erreur&nbsp;; </font><font style="vertical-align: inherit;">(iii) quant à l'exactitude, la fiabilité ou l'actualité de toute information ou contenu fourni par le biais du Service&nbsp;; </font><font style="vertical-align: inherit;">ou (iv) que le Service, ses serveurs, le contenu ou les e-mails envoyés par ou au nom de la Société sont exempts de virus, scripts, chevaux de Troie, vers, logiciels malveillants, bombes à retardement ou autres composants nuisibles.</font></font></p>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Certaines juridictions n'autorisent pas l'exclusion de certains types de garanties ou de limitations sur les droits statutaires applicables d'un consommateur, de sorte que certaines ou toutes les exclusions et limitations ci-dessus peuvent ne pas s'appliquer à Vous. </font><font style="vertical-align: inherit;">Mais dans un tel cas, les exclusions et limitations énoncées dans la présente section seront appliquées dans toute la mesure applicable en vertu de la loi applicable.</font></font></p>
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Droit applicable</font></font></h1>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Les lois du pays, à l'exclusion de ses règles de conflits de lois, régiront les présentes conditions et votre utilisation du service. </font><font style="vertical-align: inherit;">Votre utilisation de l'Application peut également être soumise à d'autres lois locales, étatiques, nationales ou internationales.</font></font></p>
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Résolution des litiges</font></font></h1>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Si vous avez des inquiétudes ou un différend concernant le Service, vous acceptez d'essayer d'abord de résoudre le différend de manière informelle en contactant la Société.</font></font></p>
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Divisibilité et renonciation</font></font></h1>
                <h2><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Divisibilité</font></font></h2>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Si une disposition de ces Conditions est jugée inapplicable ou invalide, cette disposition sera modifiée et interprétée pour atteindre les objectifs de cette disposition dans toute la mesure du possible en vertu de la loi applicable et les dispositions restantes resteront en vigueur et de plein effet.</font></font></p>
                <h2><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Renoncer</font></font></h2>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Sauf disposition contraire des présentes, le défaut d'exercer un droit ou d'exiger l'exécution d'une obligation en vertu des présentes Conditions n'affectera pas la capacité d'une partie à exercer ce droit ou à exiger une telle exécution à tout moment par la suite, et la renonciation à une violation ne constituera pas une renonciation à toute infraction ultérieure.</font></font></p>
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Traduction Interprétation</font></font></h1>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Ces Termes et Conditions peuvent avoir été traduits si Nous les avons mis à Votre disposition sur notre Service. </font><font style="vertical-align: inherit;">Vous acceptez que le texte original en francais prévaudra en cas de litige.</font></font></p>
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Modifications de ces termes et conditions</font></font></h1>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Nous nous réservons le droit, à Notre seule discrétion, de modifier ou de remplacer ces Conditions à tout moment. </font><font style="vertical-align: inherit;">Si une révision est importante, nous ferons des efforts raisonnables pour fournir un préavis d'au moins 30 jours avant l'entrée en vigueur de toute nouvelle condition. </font><font style="vertical-align: inherit;">Ce qui constitue un changement important sera déterminé à Notre seule discrétion.</font></font></p>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">En continuant à accéder ou à utiliser Notre Service après l'entrée en vigueur de ces révisions, Vous acceptez d'être lié par les conditions révisées. </font><font style="vertical-align: inherit;">Si vous n'acceptez pas les nouvelles conditions, en tout ou en partie, veuillez cesser d'utiliser le site Web et le service.</font></font></p>
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Nous contacter</font></font></h1>
                <p><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Si vous avez des questions sur ces Termes et Conditions, Vous pouvez nous contacter :</font></font></p>
                <ul>
                <li><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Par mail : </font></font><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">contact@iopi.mg</font></font></li>
                </ul>
            </p>
        `,
        tags:["STEM", "Industrie","Innovation","Environment"],
        image: require("../assets/Images/Team/Mission.JPG"),
    },
]