import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { useSelector, useDispatch } from 'react-redux'
import { newValue } from '../features/user/userSlice';
import { FcGoogle } from "react-icons/fc";


const Container = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
	z-index:3;
    right: 1rem;
	top: 1.2rem;
`

const GoogleBtn = (props) => {

    // const [duser, setduser] = useState(undefined);
    const user = useSelector((state) => state.user.value)

    useEffect(() => {
        console.log('reload')
        console.log({"user" : user})
    }, [user]);

    const responseGoogle = (response) => {
        console.log(response);
        dispatch(newValue(response))
        // setduser(response)
    }

    const responseLogout = (response) => {
        console.log('Logout successfully');
        dispatch(newValue(undefined))
        // setduser(undefined)
    }


    const dispatch = useDispatch()

    return (
        <Container>
            {
                (user !== undefined)?(
                    <div 
                        style={{
                            display: "flex"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                paddingRight: 10
                            }}
                        >
                            <span>
                                {user.profileObj.email}
                            </span>
                            <GoogleLogout
                                clientId="963666513862-1mqp5pi1cimtr82qc9nob1eh4o3llnko.apps.googleusercontent.com"
                                render={renderProps => (
                                    <span
                                        style={{
                                            borderWidth: 0,
                                            color: "blue",
                                            backgroundColor: 'transparent',
                                            cursor: "pointer",
                                            alignSelf: "end"
                                        }}
                                        onClick={renderProps.onClick}
                                        disabled={renderProps.disabled}
                                    >
                                        Logout
                                    </span>
                                )}
                                buttonText="Se déconnecter"
                                onLogoutSuccess={responseLogout}
                            />
                        </div>
                        <img 
                            style={{
                                overflow: "hidden",
                                borderRadius: 30,
                                boxShadow: "0 5px 15px #0003",
                                width: 60
                            }}
                            src={user.profileObj.imageUrl} 
                            alt="" 
                        />
                    </div>
                ):(
                    <GoogleLogin
                        clientId="963666513862-1mqp5pi1cimtr82qc9nob1eh4o3llnko.apps.googleusercontent.com"
                        render={renderProps => (
                            <button
                                style={{
                                    borderRadius: 5,
                                    borderWidth: 0,
                                    backgroundColor: "white",
                                    padding: 7,
                                    paddingLeft: 14,
                                    paddingRight: 14,
                                    boxShadow: "0 5px 15px #0003",
                                    fontSize: 12
                                }}
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                            >
                                <FcGoogle size={25} style={{marginRight: 5}}/>
                                Sign in with Google
                            </button>
                          )}
                        buttonText="Se connecter avec Google"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={'single_host_origin'}
                    />
                )
            }
        </Container>
    )
}

export default GoogleBtn
