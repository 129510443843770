// about data
export const TimelineData = [
    {
        id: 0,
        type: 1,
        date: "2022-05-16",
        place: "Orange Digital Center",
        title: "FORMATION UX-UI",
        summary: "L'aissance et la fluidité d'ue application mobile ou d'un siteweb grâce à l'User Xperience et l'UserInterface, découverts à travers une formation à la fois théoriques et pratiques",
        time: "5 Jours",
        data: `
            <img style="width: 100%; height: auto" src="/UIUXT.jpg" alt="image"/>
            <p>
                Lien d'inscription: <a style="padding-left: 2px; padding-right: 2px; border-radius: 5px; color:#555ac0" href="https://docs.google.com/forms/d/e/1FAIpQLSdsju1GPHnqqgOBlHtZw440PnxcJDdjMOxUcUojQlZ3FBWLpg/viewform?fbclid=IwAR3ctLtRA9cx3gri61NxV7aqbqrwrxyamuedRkfy8tY95DtJlbr9FkAjAh8">Inscription à la formation UX UI  - cohorte 1</a>
            </p>
            <br/>
            <h1>
                Formation UX-UI Cohorte-1
            </h1>
            <p>
                La conception de l'expérience utilisateur est un domaine vaste, multidisciplinaire et passionnant. Il façonne les produits et services que nous utilisons au quotidien et peut faire ou défaire le succès d'une entreprise ou d'une marque.
            </p>
            <p>
            Une carrière dans la conception UX est rapide et stimulante, nécessitant un ensemble de compétences très diversifiées. Si vous voulez percer dans ce domaine, il y a de quoi apprendre !
            </p>
            <br/>
            <h1>
                Programme
            </h1>
            <p>
            Dans cette formation, nous vous fournirons l'introduction ultime à la conception UX et vous dirons tout ce que vous devez savoir pour vous lancer dans cette industrie passionnante, notamment :
            </p>
            <ol>
                <li>
                    Qu'est-ce que la conception UX ?
                </li>
                <li>
                    Disciplines de conception UX : le modèle des quadrants
                </li>
                <li>
                    Que fait un UX Designer ? Processus, tâches et outils
                </li>
                <li>
                    Comment devenir un concepteur UX?
                </li>
            </ol>
            <img style="width: 100%; height: auto" src="/UIUXB.jpg" alt="image"/>
        `
    },
    {
        id: 1,
        type: 2,
        date: "2022-05-16",
        place: "FabLab",
        title: "Apprenti Fablab",
        summary: "Formation pratique pour vous faire découvrir les rouages, les projets et les métiers autours du Fablab (Forgeur numériques, prototypiste, entrepreneur maker...)",
        time: "5 Jours",
        data: undefined
    },
    {
        id: 2,
        type: 3,
        date: "2022-06-09",
        place: "Ecole Polytechnique Antananarivo",
        title: "Conférence avev Madagasikara Ekôlôjia",
        summary: "Un moment de partage et une séance de Q/A avec un écologiste oeuvrant dans la réhabilitation des terrains.",
        time: "1 Jour",
        data: undefined
    },
    {
        id: 3,
        type: 1,
        date: "2022-06-16",
        place: "Ecole Polytechnique Antananarivo",
        title: "Conférence avec le Pr Rijalalaina RAKOTOSAONA",
        summary: "Le directeur de l'ESPA va nous partager le domaine du Procédé Industriel.",
        time: "1 Jour",
        data: undefined
    },
    {
        id: 4,
        type: 2,
        date: "2022-07-01",
        place: "Stade BAREA",
        title: "ZOOM sur le métier d'ingénieur industriel",
        summary: "Un salon organisé par 5 mentions du pôle Génie des Procédés Industriels de l'Ecole Supérieure Polytechnique d'Antananarivo",
        time: "2 Jours",
        data: `
        <img style="width: 100%; height: auto" src="https://scontent.ftnr2-1.fna.fbcdn.net/v/t39.30808-6/280190622_1971705373017186_269268615965733804_n.jpg?_nc_cat=102&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeHH9UAKKcKgjm_Jg38B25iTwBDJLjkEmWTAEMkuOQSZZGWptQsrHMYMd0k3UVZVGsPrx5NcKPAQ4yHqtHvRJp8z&_nc_ohc=jviVXAne8twAX_s7D7a&_nc_ht=scontent.ftnr2-1.fna&oh=00_AT8W62eRjf_cWTKDI-7qsMchxcH6Wqudf5ecAGP23uD9uA&oe=62A0A564" alt="image"/>
        <br/>
        <p>
            <br/>
        </p>
        <h1>
        ZOOM sur le métier d'ingénieur industriel 2.
        </h1>
        <p>
            <br/>
            le ZOOM SUR LE mETIER D'INGENIEUR INDUSTRIEL sera un moment d'échanges privilégiés pendant lequel les anciens et actuels élèves ingénieurs du Génie des Procédés Industriels pourront partager l'étendu du métier d'ingénieur industriel aux jeunes. 
        </p>
        <p>
        Une carrière dans la conception UX est rapide et stimulante, nécessitant un ensemble de compétences très diversifiées. Si vous voulez percer dans ce domaine, il y a de quoi apprendre !
        </p>
        <br/>
        <p>
            Lieu: Stade BAREA<br/>
            Date: 1er et 2 juillet 2022
        </p>
        <h1>
            Au programme :  
        </h1>
        <ul>
            <li>
                Des conférences et partage . 
            </li>
            <li>
                Des expositions 
            </li>
            <li>
                Des atelier, concours de projet et jeux pour délivrer au jeunes un avant gout de l'ingénierie industriel.
            </li>
        </ul>
        <h1>
            Image d'un atelier de partage.
            <br/>
            _
        </h1>
        <img style="width: 100%; height: auto" src="https://scontent.ftnr2-1.fna.fbcdn.net/v/t39.30808-6/277799565_1950561005131623_4420875941438712075_n.jpg?_nc_cat=104&ccb=1-7&_nc_sid=8bfeb9&_nc_eui2=AeGhrWT4OzyfY1RVGidWtepU2R6VL8Ll4pHZHpUvwuXikbhA3UA5qDgeUeCx8kXglmtp7lEBBICd3bvaKFWTRieL&_nc_ohc=WLa-4CyICgMAX-lxcST&_nc_ht=scontent.ftnr2-1.fna&oh=00_AT9icMLx8vn-PPlxNUCFYNqxpPE5DW7FxJ35fzfKXCE3dg&oe=62A19312" alt="image"/>
    `
    },
]