import React from 'react'
import styled from 'styled-components'


const Text = styled.h1`
    color:${props => props.color};
    font-size: calc(4rem + 5vw);
    font-weight: bold
`

const Div = styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    top: ${props => props.top};
    bottom: ${props => props.bottom};
    left: ${props => props.left};
    right: ${props => props.right};
    z-index:0;
`


const BigTitlte = (props) => {
    return (
        <Div top={props.top}  left={props.left}  right={props.right} bottom={props.bottom}>
            <Text color={props.color}>
                {props.text}
            </Text>
        </Div>
    )
}
export default BigTitlte
