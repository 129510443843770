// blog data

export const Blogs = [

    {
        id: 0,
        thumbnail: "",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ65wS3e-UNukQbGzwfDIXNGibOeMHNUEtPMQ&usqp=CAU",
        audio: "",
        title: "Being a hero who protects his planet",
        author: "IOPI",
        voice: "Abioud RABEZANAHARY",
        tags:["Education"],
        summary: "« We do not inherit the land from our ancestors, we borrow it from our children ». Saint Exupéry, When the time comes, and we are no more, it will be our children's turn to rule. This begs the question: where will the world be by then? What will our children inherit ?",
        created_at: "2022-07-20",
        body: `
        <p>
            The question can be ambiguous at first, so much the concerned fields are vast...economic, geopolitical, technological...The earth in itself how will it be?
        </p>
        <p>
            The degradation of the environment is largely due to the irresponsible action of man on it, whether it is deforestation, slash-and-burn farming, car fumes, or the simple fact of urinating everywhere, in the actions we undertake, we are harming the planet that supports us, yet in the long term, this irresponsible action, will fall on us. Indeed, according to a WHO report, 7 million people die prematurely because of pollution in the world (2012).
        </p>
        <p>
            Since the tech4 good initiative, technology has been used for the benefit of humanity. Thus, many devices and tools have been created to contribute to the protection of the environment. To mention only the "aquatic garbage can" developed by Peter Ceglinski and Andrew Turton, which is called "SeaBin", a suction garbage can that catches all types of floating waste, but also chemical pollutants such as hydrocarbons or detergents.
        </p>
        <p>
            There are many ways to fight back against the destruction of our world, so for our sake and for the sake of future generations, let's help build a better legacy!
        </p>
        `
    },
    {
        id: 3,
        thumbnail: "",
        image: "https://www.lopinion.ma/photo/art/grande/51352512-39431697.jpg?v=1605037820",
        audio: "",
        title: "Desalination of sea water: a more profitable and ecological process",
        author: "IOPI",
        tags:["sanitation"],
        summary: "In the world, nearly 40% of the population lacks fresh water. While more than 95% of the water available on earth is sea water, desalination appears as a solution. But even if it develops, the technique has limits, in terms of cost and energy consumption",
        created_at: "2022-08-20",
        body: `
            <p>
                The consumption of drinking water per capita is increasing worldwide and on all continents there are drought peaks that make it difficult to draw fresh water. Several countries in Africa, the Middle East, but also in the Mediterranean basin, such as Spain or the Greek Islands, are equipping themselves with desalination technologies to meet the drinking water needs of their populations. "The same is true in Australia, where periods of wet weather can quickly turn into periods of drought," says Jean-Charles Bonnet, Suez's sales manager for construction in the West.
            </p>
            <h3>
                The purification of sea water
            </h3>
            <p>
                For 50 years, the group, a major player in water treatment and distribution, has been working on the subject. "For a long time now, we have opted for the reverse osmosis technique, which requires less energy than distillation, which consists of desalinating seawater by heating it, and then recovering the steam," explains Jean Charles Bonnet. In reverse osmosis seawater purification, a semi-permeable membrane - called osmosis - acts as a filter when a pressure of at least 60 bars is applied. In this process, about 50% of the seawater is transformed into fresh water and 50% into brine, a very salty water, which will be rejected into the sea.
            </p>
            <h3>
                A complex process
            </h3>
            <p>
             "Of course, the process is a little more complex," continues the manager. Indeed, the pumped seawater must first be clarified and treated as is the case for fresh water. "To optimize the reverse osmosis stage, the filtering and screening systems installed upstream are essential. And after reverse osmosis, the water must be remineralized. Otherwise it's like making distilled water," says Jean-Charles Bonnet. To remineralize the water, you simply pass it over limestone balls. "The water is naturally loaded with the elements of its container," continues the manager.
            </p>
            <h3>
                Economic arbitration
            </h3>
            <p>
                The complexity of desalination explains why the cost of producing drinking water in desalination plants is almost double that of fresh surface water. "But for some cities, such as Barcelona, which have opted to install a seawater desalination plant when at certain times of the year there is not enough fresh water, the cost of desalination compares to other alternatives, such as bringing water by boat," illustrates Jean Charles Bonnet.
            </p>
            <h3>
                The tracks of innovation
            </h3>
            <p>
                However, the players in the sector, such as Suez, are investing in research to reduce production costs. The price of osmosis membranes has already been considerably reduced and research is currently focused on reducing the energy consumption of production centers, by working on the installation of voltaic panels and energy recovery. Reducing the impact of the process on the environment is also a priority. The discharge of brine, a very salty water, into the sea could have an impact if it is done too close to the coast. In several of its facilities, Suez has chosen to mix this brine with water treated by wastewater treatment plants, including fresh water, and to disperse it at a depth of more than 50 metres a few kilometers from the coast.
            </p>
        `
    },
    {
        id: 4,
        thumbnail: "",
        image: "https://www.curieux.live/wp-content/uploads/2019/10/urine-engrais-biologique-toopi-organics.jpg",
        audio: "",
        title: "Is urine a fertilizer?",
        author: "IOPI",
        tags:["sanitation","Education"],
        summary: "Each year, humans produce 500 liters of urine, which is the equivalent of 21 kg of dry matter. This urine, which our society treats as waste, is not waste for plants. On the contrary, and this is the miracle of nature, human urine is an excellent fertilizer for plants! And what if it was the best fertilizer there is?",
        created_at: "2022-08-26",
        body: `
            <h3>
                What is in our urine?
            </h3>
            <p>
                Urine is a biological liquid, filtered by the blood and secreted by the kidneys, which contains waste products that the body must eliminate. An adult person produces between 1.5 and 2 liters of urine per day. 95% of it is water and the remaining 5% is urea (2%), minerals and other compounds in small quantities.
            </p>
            <p>
                Urea is rapidly broken down into ammonia by an enzyme (urease) and then, in the soil, bacteria convert it into nitrogen that can be taken up by plants.
            </p>
            <p>
                Parmi les minéraux intéressants contenus dans l'urine, citons les sodium, calcium, magnésium, oligo-éléments mais surtout phosphore et potassium qui y sont en proportion non négligeable : ce sont des composants habituels des engrais dits NPK.
            </p>
            <p>
                The content of urine varies according to the person, the diet, possible pathologies, medications taken, etc. However, on average, 1 liter of urine contains: 5 to 6 g of nitrogen (N), 1 to 1.5 g of phosphorus (P), 1.5 to 2 g of potassium (K).
            </p>
            <p>
                According to this analysis, urine is a natural nitrogen fertilizer that can compete with dried blood, an organic fertilizer with a "kick", or ground horn, both of which are particularly rich in nitrogen: no wonder it can be used in the vegetable garden.
            </p>
            <p>
                Be careful, urine will probably contain too much sodium for plants if you eat very salty food, hence the interest in diluting it in this case. Similarly, if you are taking medication, it will contain drug residues: although they are destroyed after a period of one month, like any pathogens, it is better to use healthy urine. The urine collected in the morning, when you get up, concentrates the maximum amount of nutrients.
            </p>
            <h3>
                Using urine as fertilizer
            </h3>
            <p>
                The urine will bring a lot of nitrogen. However, nitrogen favors the development of the leaves which are weakened by their thinness, thus more sensitive to diseases, while the fruits develop little. Therefore, the period of nitrogen fertilizer application must be limited to spring to accompany the vegetative start. However, leafy vegetables (cabbage, chard...) that are very greedy for nitrogen can receive it after spring, during their growth period. In all cases, urine applications should be stopped 1 month before harvest.
            </p>
            <p>
                Pure urine can be sprayed on the soil amended with a well matured compost, about 15 days before planting the crops, so that the urine can mineralize. To obtain sufficient pure urine, it must be stored without losing its properties, i.e. for a maximum of 1 month, preferably in the dark or at least in the shade.
            </p>
            <p>
                Diluted urine is poured at the foot of leafy vegetables, especially during the growing season, with a dilution of 1 liter of urine for 9 to 19 liters of water (5 to 10%), at a frequency of about 15 days to 3 weeks. If you regularly pour pure urine on plants, it will act as a weed killer by burning them, which is why it is better to use it diluted, especially on grass if you want to accelerate its growth.
            </p>
            <p>
                Over the season, a contribution of 1.5 liters of pure urine/m² seems to be a good average, knowing that the most nitrogen-hungry plants will tolerate more while the most sober ones will require less.
            </p>
            <p>
                If the soil is mulched (straw, wood chips), it is even better because the carbon will compensate for the high nitrogen content of the urine. And if you use ash water* to dilute the urine, you will obtain a complete fertilizer providing, in addition to nitrogen, phosphorus and potassium necessary for fruiting: vegetables, fruits, fruit trees, rose bushes, annual flowers and shrubs will be able to benefit from it.
            </p>
            <h3>
                Using urine as a compost gas pedal
            </h3>
            <p>
                The other use of urine in the vegetable garden is in the compost bin: it accelerates the decomposition of organic matter, especially carbonaceous hard waste (dead leaves, straw, dry toilet waste, wood chips, etc.).
            </p>
            <p>
                To conclude, let's say that if you don't have a separate toilet that allows you to isolate feces from urine, the latter is not very convenient to collect on a daily basis. If you are socially accepted, you can opt for dry toilets which, on the compost heap, will bring all the benefits of urine and more (saving water and reprocessing)!
            </p>

        `
    },
    {
        id: 5,
        thumbnail: "",
        image: "https://www.ompe.org/wp-content/uploads/2017/03/technologie-verte-propre.jpg",
        audio: "",
        title: "Green or clean technologies: definition and issues",
        author: "IOPI",
        tags:["Clean technology"],
        summary: "The term technology is very often not correlated with environmental protection. Indeed, technological progress is often a sign of human action and therefore of destruction or pollution. Fortunately, this is not always the case! Some of them are called green or clean because of their purpose: to maintain and preserve natural ecosystems.",
        created_at: "2022-09-01",
        body: `
            <h3>
                Definition and issues of green technologies
            </h3>
            <p>
                In recent years, the word "green" has gained a considerable place in our society, in our workplace or in our home. But, if the term "green technologies" is still rather unknown, they are nevertheless present all around us. Their primary objective is simple: to reduce the harmful effects of human activity on the environment. The key is to "produce more with less".
            </p>
            <p>
                The green technologies most often encountered are waste recycling, wastewater treatment, renewable energies... Many researchers and scientists have also studied the issue to develop green technologies whose purpose is, if not to stop the devastating actions of man, try to prevent or reduce them. It is for example the case of the following inventions:
            </p>
            <li>
                Ecological concrete: This ecological concrete does not contain harmful nitrogen compounds, such as nitrogen oxide. Although Holland has already been able to test it, it quickly ran into a cost problem, although it is very effective. If it also contains titanium dioxide, like other concrete, it needs more of it, which makes it more expensive;
            </li>
            <li>
                Cleaning the oceans: if plastics are very useful in our daily life, they are also very polluting when they end up in the environment. In addition to taking hundreds of years to disappear, they are responsible for the death of thousands of marine animals. Some companies have therefore developed plastic processing machines. OMPE has also been supporting for many years the setting up of giant anti-plastic barges;
            </li>
            <li>
                A multitude of other green or clean technologies are emerging in the building, industry and transportation sectors at various stages: manufacturing, energy saving, investment, research…
            </li>
            <p>
                But be careful because the term "green" has become very fashionable and is also a great advertisement to attract consumers who believe they are protecting the environment... So how do you know if what you are buying is green or not? One of the keys to making sure your product meets the requirements of an environmentally friendly technology is to consider some of the following characteristics:
            </p>
            <li>
                Low energy consumption: New appliances normally consume less electricity. This is particularly important in countries where electricity is produced in coal-fired power plants.
            </li>
            <li>
                Reduced use of toxic elements: this is basic and the least you can do when talking about a green product... So avoid as much as possible products containing lead, mercury, cadmium and barium, which are very harmful to the environment.
            </li>
        `
    },
    {
        id: 6,
        thumbnail: "",
        image: "https://img.passeportsante.net/1200x675/2021-05-06/i106779-10-choses-savoir-sur-urine.webp",
        audio: "",
        title: "Save more with urine",
        author: "IOPI",
        tags:["environment"],
        summary: "The future cannot be predicted, which is why it is important to save as much as possible, but the means used here seem unusual, to say the least....Since when? When? How? Why?",
        created_at: "2020-09-10",
        body: `
            <p>
                If the question is easy to ask, the answers require more thought. Indeed, it is difficult to believe that this liquid spilled randomly everywhere can claim to be a source of wealth.
            </p>
            <p>
                It is clear that the richness of urine does not lie in its foul and disgusting nature but rather in the thoughtful and responsible actions we take in relation to it.
            </p>
            <p>
                OMS shows that for every dollar invested in sanitation, approximately $5.50 is saved through reduced health care costs, productivity gains and fewer premature deaths. This is concrete proof that adopting good practices is beneficial.
            </p>
            <p>
                In addition, technologies that transform urine into usable energy are currently being developed, such as the MFC (Microbial fuel cell), which is based on the same principle as the fuel cell. In short, imagine reducing lighting costs with urine!
            </p>
            <p>
                So, combine the useful with the unpleasant, take good initiatives and save more with urine.
            </p>
        `
    },
    {
        id: 7,
        thumbnail: "",
        image: "https://static.allodocteurs.fr/btf-11-23652-thumb-660/37bf3fe9baed24c2e8ea8661ef72a764/media.jpg",
        audio: "",
        title: "Urine more than just a waste product?",
        author: "IOPI",
        tags:["environment"],
        summary: "« Tsy azo ipipizana eto », Most of the alleys in Antananarivo are covered with these warnings, the fact is that urine by its smell and color is considered disturbing. What is it really?",
        created_at: "2022-09-20",
        body: `
            <p>
                For your information, human urine contains over 96% water, 2% urea, 0.2% creatine, ammonia and uric acid, 2.8% salts and other dissolved ions.
            </p>
            <p>
                Despite the fact that it is not unanimously accepted in Malagasy society, urine makes its contribution to the improvement of human living conditions
            </p>
            <p>
                In some practices it is used to sanitize a wound and allow it to heal. Following specific procedures, some people also use it as a base to create a fertilizer and improve the productivity of the land. In the field of medicine, a simple study of the urine taken from an individual can provide information on the state of health of the latter.
            </p>
            <p>
                And again, this is only a partial list of the services that urine can provide.
            </p>
            <p>
                In short, behind its hideous and fetid appearance, urine is not a simple waste, far from it, it must be valorized in order to be able to bring its contribution towards society.
            </p>
        `
    },
    {
        id: 8,
        thumbnail: "",
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9_hoy-m1SW3-BsqtTIZsk9CCYnqMK3NkXA8-7nUQ3TojENHNr1VYzqpy-qFuVnRVojzY&usqp=CAU",
        audio: "",
        title: "Green gas: from organic waste to biogas and biomethane production",
        author: "IOPI",
        tags:["Renewable energy", "environment"],
        summary: "Lighting, heating or even driving thanks to our waste? It is possible! Obtained by fermentation of organic matter, biogas can be used to produce electricity, heat and even fertilizer. The purified biogas, called biomethane, with characteristics equivalent to those of natural gas, can be used in natural gas networks or as fuel for engines. A source of renewable energy that is as promising as it is virtuous... but whose cost is still relatively high, requiring support from public authorities.",
        created_at: "2022-09-26",
        body: `
            <h3>
                    WHAT IS BIOGAS?
            </h3>
            <p>
                    Biogas is a gas that is most often produced by the fermentation of organic elements under the action of micro-organisms. This fermentation process, which occurs spontaneously in natural ecosystems (marshes, rice fields, soils, mammalian intestines, etc.), is called methanization. It is now mastered by man and allows us to recover our waste while replacing certain sources of fossil energy. The result: a reduction in our greenhouse gas emissions.
            </p>
            <h3>
                    HOW IS BIOGAS MADE?
            </h3>
            <p>
                    All organic matter, whether of animal or vegetable origin, can be methanized to be transformed into biogas. At present, the most common methods used are :
            </p>
            <li>
                    Agricultural waste (animal manure, crop residues such as straw, etc.),
            </li>
            <li>
                    Green waste (grass clippings, etc.),
            </li>
            <li>
                    Waste from the food industry (slaughterhouses, vineyards, dairies, etc.)
            </li>
            <li>
                    Sludge from wastewater treatment plants
            </li>
            <p>
                    Methanization must take place in a confined facility called a digester, in the absence of oxygen. The micro-organisms naturally present in the organic matter degrade it and produce biogas, as well as a residue called digestate.
            </p>
            <p>
                    The biogas is a mixture of :
            </p>
            <li>
                 50 to 70% methane,
            </li>
            <li>
                 20 to 50% carbon dioxide (CO2),
            </li>
            <li>
                 some traces of nitrogen, ammonia and hydrogen sulfide
            </li>
            <p>
                 Only methane is exploited, after purification of the other gases (depending on its level of purity, CO2 could also be recovered).
            </p>
        `
    },
] 